<template>
  <v-row class="pb-12">
    <v-toolbar class="transparent" flat height="80">
      <v-toolbar-title class="subtitle-1 font-weight-bold">Organizaciones</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-row align="center" justify="end" no-gutters>
          <v-btn class="px-2" small outlined height="35" :ripple="false"><v-icon left>mdi-export-variant</v-icon>Exportar</v-btn>
          <v-menu close-on-click close-on-content-click offset-y :nudge-bottom="8" right transition="slide-y-transition">
            <template v-slot:activator="{on, attrs}">
              <v-btn class="px-2 ml-2" v-on="on" v-bind="attrs" small outlined height="35" :ripple="false">
                <v-icon left>mdi-filter-plus</v-icon>Filtros
              </v-btn>
            </template>
          </v-menu>
        </v-row>
      </v-toolbar-items>
    </v-toolbar>

    <v-row class="mt-0 px-0">
      <v-col cols="3" v-for="(d, i) in organizations" :key="i">
        <v-hover v-slot="{ hover }">
          <v-sheet class="py-6 px-4 text-center" outlined rounded="md" :elevation="hover ? 6 : 0" :ripple="false">
            <v-row class="px-2" justify="end">
              <v-menu close-on-click close-on-content-click offset-y :nudge-bottom="8" left transition="slide-y-transition">
                <template v-slot:activator="{on, attrs}">
                  <v-btn v-bind="attrs" v-on="on" small outlined height="35" :ripple="false" width="35"><v-icon size="24" color="fontBody">mdi-dots-vertical</v-icon></v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item class="px-2 pb-2" :to="{ name: 'OrganizationRetrieveUpdate', params: { id: d.id } }">
                    <v-list-item-title class="body-1 text--secondary"><v-icon class="mt-n1 text--secondary" left size="18">mdi-book-edit</v-icon>Ver o editar</v-list-item-title>
                  </v-list-item>
                  <v-divider class="mx-2" />
                  <v-list-item class="px-2 pt-2" :to="{ name: 'UserList', query: { organizations__in: d.id } }">
                    <v-list-item-title class="body-1 text--secondary"><v-icon class="mt-n1 text--secondary" left size="18">mdi-account-group</v-icon>Administrar usuarios</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
            <v-badge avatar bordered overlap>
              <template v-slot:badge>
                <v-avatar>
                  <v-img :src="require(`@/assets/flags/flag-${d.country?.toLowerCase()}.svg`)"></v-img>
                </v-avatar>
              </template>
              <v-avatar v-if="d.brand" class="py-4 outlined" rounded="md" size="56">
                <img :src="require(`@/assets/brands/${d.brand?.toLowerCase()}`)" :alt="d.brand">
              </v-avatar>
              <v-avatar v-else class="py-4" color="background" rounded="md" size="56">
                <v-icon size="28" color="fontBody">mdi-domain</v-icon>
              </v-avatar>
            </v-badge>
            <span class="d-block mt-4 text-truncate fontBody--text font-weight-medium subtitle-2">{{d.name}}</span>
            <span class="d-block my-2 body-2 fontDraw--text">{{ d.country === 'CL' ? 'RUT' : 'RUC'}} {{d.tax_id}}</span>
          </v-sheet>
        </v-hover>
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
import accountsList from '@/data/accountsList' // example
export default {
  data: () => ({
    organizations: accountsList
  })
}
</script>
